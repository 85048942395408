import React from "react";
import { connect } from "react-redux";

class Header extends React.Component {
    render() {
        return (
            <React.Fragment>
                <header className="header">
                    <nav className={`navbar navbar-expand-lg fixed-top ${!this.props.terms ? 'bg-transparent' : 'gradient-bg'}`}>
                        <div className="container">
                           {this.props.app? <img style={{ height: '40px' }} height={35} src={this.props.isColorLogo && this.props.isColorLogo === true ? "assets/img/admin.png" : "assets/img/admin.png"}
                                    alt="logo" className="img-fluid" />:<a className="navbar-brand" href="/">
                                <img style={{ height: '40px' }} height={35} src={this.props.isColorLogo && this.props.isColorLogo === true ? "assets/img/admin.png" : "assets/img/admin.png"}
                                    alt="logo" className="img-fluid" />
                            </a>}
                           { this.props.terms == false && <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="ti-menu"></span>
                            </button>}

                            {this.props.terms == false ? <div className="collapse navbar-collapse h-auto" id="navbarSupportedContent">
                                <ul className="navbar-nav ml-auto menu">
                                {/* dropdown-toggle  this class for dropdown version*/}
                                    <li><a href="/#" className="page-scroll"> Home</a> 
                                    </li>
                                    <li><a href="#about" className="page-scroll">About</a></li>
                                    <li><a href="#contact" className="page-scroll">Contact</a></li>
                                </ul>
                            </div> :
                                <div className="p-4">
                                   {this.props.app?<div className="p-2"></div>: <a href="/">
                                        <i class="fas fa-home text-white" />
                                    </a>}
                                </div>
                            }
                        </div>
                    </nav>
                </header>
            </React.Fragment>
        );
    }
}

export default connect(state => ({}))(Header);
