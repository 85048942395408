import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Theme3 from "../themes/theme3";
import Terms from "../themes/Terms";
import PrivacyPolicy from "../themes/faq";
import NotFound from "../themes/404";
import AppTerms from "../themes/AppTerms";
import AppPrivacy from "../themes/AppPrivacyPolicy";
import CheckDevice from "../themes/CheckDevice";


export default class Routes extends React.PureComponent {
  render() {
    return (
      <React.Fragment>
        <Router>
          <Switch>
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy-policy" component={PrivacyPolicy} />
            <Route exact path="/app-terms" component={AppTerms} />
            <Route exact path="/app-privacy-policy" component={AppPrivacy} />
            <Route exact path="/404" component={NotFound} />
            <Route exact path="/download" component={CheckDevice} />
            <Route path="/" component={Theme3} />
          </Switch>
        </Router>
      </React.Fragment>
    );
  }
}
