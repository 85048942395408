import React, { Component } from "react";

import Header from "../components/Header/header";
import Footer from "../components/Footer";
import PrivacyPolicy from "../components/Faq/privacyText";

class Theme extends Component {
  render() {
    return (
      <React.Fragment>
        <Header terms={true} />
        <div className="main">
          <PrivacyPolicy />
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Theme;
