import React from "react";
import { connect } from "react-redux";

class PromoSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promo: {}
        };
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section className="promo-block ptb-100">
                    <div className="container">
                        <div className="row align-items-center justify-content-between">
                            <div className="col-md-6 col-lg-6">
                                <div className="promo-content-wrap">
                                    <h2>Dinner done? Ready to go?</h2>
                                    <p>Credibly matrix extensible sources through tactical leadership skills. Energistically strategize error-free sources vis-a-vis client-focused value. </p>
                                    <ul className="check-list-wrap pt-3">
                                        <div style={{ display: 'flex' }}>
                                            <i style={{fontSize:'1.25rem',color:'#2b5193',paddingTop:'0.7rem'}} class="fa-solid fa-circle-exclamation icon-size-xs"></i> <div style={{padding:'.5rem 0 .5rem 0.6rem'}}><strong>Challenge</strong> – Customers who visit a restaurant or a mall and use the valet parking service often have to wait for the valet to return their car. This can result in a negative experience as customers may feel inconvenienced or frustrated by the wait.</div>
                                        </div>
                                       <li><strong>Solution</strong> – A well interactive app will help in enhancing the customer experience while they wait for their car to be delivered.</li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                                <div className="row">
                                    <div className="col-md-6 col-lg-6">
                                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4">
                                            <div className="card-body">
                                                <div className="pb-2">
                                                    <span className="fas fa-concierge-bell icon-size-md color-secondary"></span>
                                                </div>
                                                <div className="pt-2 pb-3">
                                                    <h5>Modular</h5>
                                                    <p className="mb-0">All components are built to be used in combination.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4">
                                            <div className="card-body">
                                                <div className="pb-2">
                                                    <span className="fas fa-window-restore icon-size-md color-secondary"></span>
                                                </div>
                                                <div className="pt-2 pb-3">
                                                    <h5>Responsive</h5>
                                                    <p className="mb-0">Quick is optimized to work for most devices.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4">
                                            <div className="card-body">
                                                <div className="pb-2">
                                                    <span className="fas fa-sync-alt icon-size-md color-secondary"></span>
                                                </div>
                                                <div className="pt-2 pb-3">
                                                    <h5>Scalable</h5>
                                                    <p className="mb-0">Remain consistent while developing new features.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-lg-6">
                                        <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4">
                                            <div className="card-body">
                                                <div className="pb-2">
                                                    <span className="fas fa-bezier-curve icon-size-md color-secondary"></span>
                                                </div>
                                                <div className="pt-2 pb-3">
                                                    <h5>Customizable</h5>
                                                    <p className="mb-0">Change a few variables and the whole theme adapts.</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    state
}))(PromoSection);
