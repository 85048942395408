import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const CheckDevice = () => {
  const location = useLocation()
  function redirectLink() {
    let link = document.createElement("a");
    link.href = getMobileOperatingSystem();
    link.click()
  }

  function getMobileOperatingSystem() {
    const search = location.search.replace("?", "")
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return search === "d" ? "https://apps.apple.com/in/app/parkpro-driver/id6470093492" : "https://apps.apple.com/in/app/parkpro-valet/id6470144830";
    } else {
      return search === "d" ? "https://play.google.com/store/apps/details?id=com.parkpro.driver" : "https://play.google.com/store/apps/details?id=com.parkpro.customer";
    }
  }

  useEffect(() => {
    redirectLink()
  }, [])

  return (
    <React.Fragment>
      <div style={{ width: '100vw', height: '100vh', backgroundColor: '#183971', textAlign: 'center', lineHeight: '100vh' }}>
        <div className="spinner-border text-warning" role="status">
        </div>
      </div>
    </React.Fragment>
  );
}

export default CheckDevice;
