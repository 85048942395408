import React, { Component } from "react";

import Header from "../components/Header/header";
import PrivacyPolicy from "../components/Faq/privacyText";

class AppPrivacy extends Component {
  render() {
    return (
      <React.Fragment>
        <Header  app={true} terms={true} />
        <div className="main">
          <PrivacyPolicy />
        </div>
      </React.Fragment>
    );
  }
}

export default AppPrivacy;
