import React from "react";
import { connect } from "react-redux";
import _data from "../../data";

class PromoSection extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            promo: {}
        };
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */

        this.setState({
            promo: _data.promo
        });
    }

    render() {
        return (
            <React.Fragment>
                <section id="about"  className="promo-section ptb-100 position-relative overflow-hidden">
                    {this.props.hideShape && this.props.hideShape === true && (
                        <div className="effect-2 opacity-1">
                            <svg version="1.1" x="0px" y="0px" viewBox="0 0 463.6 616" style={{ enableBackground: "new 0 0 463.6 616" }} className="injected-svg svg_img dark-color">
                                <path d="M148.4,608.3C25.7,572.5-3.5,442.2,0.3,375.8s24.8-117,124.8-166.5s125.7-77.4,165-129.6 c43.2-57.4,96.5-94.4,127.9-73c63,43,53.9,280,14,358s-68.9,75.5-98.9,118.7S271,644,148.4,608.3z"></path>
                            </svg>
                        </div>
                    )}
                    <div className="container">
                        <div>
                            <div className="col-md-12 col-lg-12">
                                <div className="promo-content-wrap">
                                    <h2>Dinner done? Ready to go?</h2>
                                    {/* <p>Credibly matrix extensible sources through tactical leadership skills. Energistically strategize error-free sources vis-a-vis client-focused value. </p>
                                    <ul className="check-list-wrap">
                                        <div style={{ display: 'flex' }}>
                                            <i style={{ fontSize: '1.25rem', color: '#fbb414', paddingTop: '0.7rem' }} class="fa-solid fa-circle-exclamation icon-size-xs"></i> <div style={{ padding: '.5rem 0 .5rem 0.6rem' }}><strong>Challenge</strong> – Customers who visit a restaurant or a mall and use the valet parking service often have to wait for the valet to return their car. This can result in a negative experience as customers may feel inconvenienced or frustrated by the wait.</div>
                                        </div>
                                        <li><strong>Solution</strong> – A well interactive app will help in enhancing the customer experience while they wait for their car to be delivered.</li>
                                    </ul> */}
                                </div>
                            </div>
                        </div>
                        <div className="row pt-2">
                            <div className="col-md-6 col-lg-4">
                                <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4">
                                    <div className="card-body">
                                        <div className="pb-2 pt-2">
                                            <span className="fa-solid fa-car icon-size-md color-secondary"></span>
                                        </div>
                                        <div className="pt-2 pb-2">
                                            <h5>For a customer</h5>
                                            <p className="mb-0">The app offers convenience of requesting Valet service, helps avoid long waiting periods and ensures safety and security of customers' vehicles.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4">
                                    <div className="card-body">
                                        <div className="pb-2 pt-2">
                                            <span className="fa-solid fa-hotel icon-size-md color-secondary"></span>
                                        </div>
                                        <div className="pt-2 pb-2">
                                            <h5>For a client</h5>
                                            <p className="mb-0">The app provides a great way to enhance customer experiences, while the backend software helps streamlining operations and generating data insights.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4">
                                <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4">
                                    <div className="card-body">
                                        <div className="pb-2 pt-2">
                                            <span className="fa-solid fa-person-military-pointing icon-size-md color-secondary"></span>
                                        </div>
                                        <div className="pt-2 pb-2">
                                            <h5>For a Valet driver</h5>
                                            <p className="mb-0">The app enables quick, easy job allocations for the drivers and maintains efficient communication channeled to the customers and location managers.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-md-6 col-lg-3">
                                <div className="card border-0 single-promo-card single-promo-hover p-2 mt-4">
                                    <div className="card-body">
                                        <div className="pb-2">
                                            <span className="fas fa-bezier-curve icon-size-md color-secondary"></span>
                                        </div>
                                        <div className="pt-2 pb-3">
                                            <h5>Customizable</h5>
                                            <p className="mb-0">Change a few variables and the whole theme adapts.</p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    state
}))(PromoSection);
