import React, { Component } from "react";

import Header from "../components/Header/header";
import Footer from "../components/Footer";
import TermsText from "../components/Faq/TermsText";

class AppTerms extends Component {
  render() {
    return (
      <React.Fragment>
        <Header app={true} terms={true} />
        <div className="main">
          <TermsText/>
        </div>
      </React.Fragment>
    );
  }
}

export default AppTerms;
