import React from "react";
import { connect } from "react-redux";

class Download extends React.Component {
  render() {
    return (
      <React.Fragment>
        <section className="position-relative overflow-hidden ptb-100">
            <div className="mask-65"></div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-8">
                        <div className="section-heading text-center text-white">
                            <h2 className="text-white">There is something in it for everyone</h2>
                            <p>Start working with that can provide everything you need to generate awareness, drive traffic, connect. Efficiently transform granular value with client-focused content.</p>
                        </div>
                        <div className="video-promo-content my-5">
                            {/* <a href="https://www.youtube.com/watch?v=9No-FiEInLA" className="popup-youtube video-play-icon text-center m-auto"><span style={{color:'#2b5193'}} className="ti-control-play"></span> </a> */}
                        </div>
                    </div>
                </div>
                <div className="row justify-content-md-center justify-content-sm-center">
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="bg-white p-5 rounded text-center shadow mt-lg-0 mt-4">
                            <div className="icon-text-wrap">
                                <i className="fa-solid fa-car icon-size-md color-primary mb-2"></i>
                                <h5>For a customer</h5>
                            </div>
                            <div className="p-20px">
                                <p className="m-0px">The app offers convenience of requesting Valet service, helps avoid long waiting periods and ensures safety and security of customers' vehicles.</p>
                                {/* <a className="btn btn-brand-02 btn-sm btn-rounded" href="/#">Download Now</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="bg-white p-5 rounded text-center shadow mt-lg-0 mt-4">
                            <div className="icon-text-wrap">
                                <i className="fa-solid fa-hotel icon-size-md color-primary mb-2"></i>
                                <h5>For a client</h5>
                            </div>
                            <div className="p-20px">
                                <p className="m-0px">The app provides a great way to enhance customer experiences, while the backend software helps streamlining operations and generating data insights.</p>
                                {/* <a className="btn btn-brand-02 btn-sm btn-rounded" href="/#">Download Now</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-6 col-md-6 col-lg-4">
                        <div className="bg-white p-5 rounded text-center shadow mt-lg-0 mt-4">
                            <div className="icon-text-wrap">
                                <i className="fa-solid fa-person-military-pointing icon-size-md color-primary mb-2"></i>
                                <h5>For a Valet driver</h5>
                            </div>
                            <div className="p-20px">
                                <p className="m-0px">The app enables quick, easy job allocations for the drivers and maintains efficient communication channeled to the customers and location managers.</p>
                                {/* <a className="btn btn-brand-02 btn-sm btn-rounded" href="/#">Download Now</a> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
      </React.Fragment>
    );
  }
}

export default connect(state => ({}))(Download);
