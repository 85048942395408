import React from "react";

class TermsText extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section className="ptb-100 gray-light-bg pt-100 mt-100">
                    <div className="container pt-100">
                        <div className="row justify-content-start">
                            <div className="col-md-12 col-lg-8">
                                <div className="section-heading mb-3 text-start">
                                    <h2>Terms And Conditions</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>1. Introduction</h5>
                                    <p>
                                        Welcome to Park Pro! <br />
                                        “Park Pro” (also referred to as “we” or “us” or “our”) aims to make the entire Valet Parking experience secure and hassle free for the users. Both the Park Pro website and application are collectively referred to as <b> "Website"</b> under this document. This policy shall govern the Park Pro website and its associated application. This document serves as an electronic record under the Information Technology Act 2002 or any applicable law contains the terms and conditions which govern Your usage of our website, services and tools in addition to the incorporated governing policies, laws and our Privacy Policy. Kindly read these terms and conditions carefully before using the website.
                                        For the purpose of these Terms and Conditions, unless the context otherwise states,<b>  “You”</b> or <b> “Your” </b> or <b> “user” </b> refers to any natural or legal person who uses or accesses the website in any manner or is registered on the platform by providing data. The term <b>“we”  </b> or<b>  “us”  </b>or <b>“our”   </b>shall be construed to mean Park Pro and its affiliates.
                                        Upon Your usage of the website including but not limited to buying of goods, posting reviews etc. You will be subject to the applicable terms and conditions which may be amended by us from time to time at our sole discretion without prior consent from You and notice to You. You shall ensure to review these updated/amended terms and conditions in a timely manner for any changes or updates. Your continued use of the website and all the services provided thereunder post any changes will be taken as Your acceptance and agreement of the same.
                                        Following are the terms and conditions of use. For a seamless user experience kindly go through the same prior to Your use of the website.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>2. Acceptance</h5>
                                    <p>
                                        By accessing and/or using any of the Services, You agree to be bound by this Agreement. The use of our website is subject to the following terms and conditions of use, as amended from time
                                        to time (the “Terms”). The Terms are to be read together by You with any terms, conditions or disclaimers provided in the pages of our website. Please review the Terms carefully. The Terms apply to
                                        all users of our website, including without limitation, users who are browsers, customers, merchants, vendors and/or contributors of content.
                                        These Terms of Use constitute a legally binding agreement made between You, whether personally or on behalf of an entity (“You”) and Park Pro (“we,” “us” or “our”), concerning Your access to and use of the website as well as any other media form, media channel, mobile website or mobile application related, linked, or otherwise connected thereto (collectively, the “Site”).

                                        Please be sure to review these terms regularly for any updates/changes/modifications (“Revisions”). Your continued use of Our Site following the Revisions shall mean that You accept and agree to the Revisions. Subject to Your strict compliance with these Terms and any other policies laid down by us, we grant You a personal, non-exclusive, non-transferable, limited license to access and use the website.

                                        By using our website, You indicate that You accept these Terms of website Use and that You agree to abide by them. If You do not agree to these Terms of website Use, please refrain from using our website.

                                        The information which is provided on the website is not intended for distribution to or use by any individual in any jurisdiction or country where such distribution or use would be contrary to law or any legislation or which would subject us to any registration requirement within such jurisdiction or country.

                                        Accordingly, anyone who choose to access the Site from other locations do so on their own initiative and are solely responsible for compliance with local laws, if and to the extent local laws are applicable.

                                        This electronic record is generated by a computer system and does not require any physical or digital signature for it to be binding on parties.



                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>3.	Agreement </h5>
                                    <p>
                                        In addition to the other website policies, these Terms and Conditions apply to the access and use of the Services of the website. Additional terms may apply to specific features of the Site Services, and such terms will be presented when You engage with such features. To the extent of any conflict between different agreements that You have with Park Pro, Terms and Conditions and any feature-specific terms govern with respect to the use of the Services.

                                        If You are accessing or using the website Services on behalf of a business or third party, You represent and warrant that You have the authority to do so and to bind that business or third party to these Terms and Conditions, and Your agreement to these Terms and Conditions will be treated as their respective agreement. In this event, Park Pro may hold You responsible for violations of this agreement by that
                                        business or third party, and “You”, “Your” and “party” will also refer and apply to that business or third party.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>4.	Copyright Notice </h5>
                                    <p>
                                        Subject to the express provisions of these terms and conditions: (a) we, together with our licensors, own and control all the copyright and other intellectual property rights in our website; and (b) all the copyright and other intellectual property rights in our website are reserved.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>5.	Intellectual Property</h5>
                                    <p>All Site contents, registered and unregistered trademarks, designs, information and images (the “Intellectual Property”) belong to us. The Intellectual Property is protected by international trademark, copyright, privacy, and other intellectual property laws. You obtain no interest in that Intellectual Property, provided. All rights not expressly granted under these Terms are reserved by us. Unless expressly stated otherwise, You shall not copy, reproduce or replicate any Intellectual Property. We take no responsibility and assume no liability for any comments posted by users or any third party.
                                        Copyright Infringement Claims. Unauthorized copying, distribution, modification, public display, or public performance of copyrighted works is an infringement of the copyright holder’s rights. You agree that You will not use our Site to infringe anyone’s intellectual property rights. We will investigate copyright infringement claims if they are reported to us to support@parkproapp.com.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>6.	User Responsibilities </h5>
                                    <p>a. Booking and Payments: Users can make reservations for valet parking services through the website. By making a booking, You agree to provide accurate and complete information. Payments are processed securely, and You agree to pay the specified fees for the services.<br />

                                        b. Vehicle Condition: You are responsible for the condition of Your vehicle at the time of valet service. We are not liable for any pre-existing damages or losses.<br />

                                        c. Compliance with Laws: Users must comply with all applicable laws and regulations while using our services and website.<br />
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>7.	Booking Confirmation  </h5>
                                    <p>Confirmation Email: After making a booking, you will receive a confirmation email with details of the reservation. This email serves as proof of your booking.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>8.	User Comments, Feedback and Other Submissions </h5>
                                    <p>
                                        All comments, feedback, suggestions and ideas disclosed, submitted or offered to Park Pro in connection with Your use of this Mobile app (collectively, “Comments”), shall be and remain the exclusive property of Park Pro and may be used by Park Pro in any medium and for any purpose worldwide without obtaining Your specific consent. For example, Your Comments could be used on this Mobile app and in radio, television and print advertisements. Your first name, first initial of Your last name, and town and state may be used with any Comments You submit. Park Pro is not under any obligation to maintain Your Comments (and the use of Your first name and first initial of Your last name with any comments) in confidence, to pay to You any compensation for any Comments submitted, or to respond to any of Your Comments. You agree You will be solely responsible for the content of any Comments You make.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>9.	Reservation and Payment  </h5>
                                    <p>a. To make a reservation, you must provide accurate and complete information through our booking system. <br />

                                        b.	Payment for valet parking services is due upon reservation, unless otherwise specified.<br />

                                        c.	Cancellations must be made within the specified time frame for a full or partial refund, as indicated during the reservation process.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>10.	Use of Services </h5>
                                    <p>a.	You agree to use our services solely for lawful purposes and in accordance with these Terms and Conditions.<br />

                                        b.	You are responsible for ensuring that all information provided during reservation and use of services is accurate and up to date.<br />

                                        c.	Any misuse, unauthorized access, or tampering with our website or services may result in legal action.


                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>11.	Liability And Indemnity  </h5>
                                    <p>a.	Damage or Loss: While we take utmost care in handling vehicles, Park Pro is not responsible for any damage, loss, or theft of personal property left in the vehicle. Furthermore, Park Pro shall not assume any liability for theft or damage to the vehicle itself under any circumstances. <br />
                                        b.	Liability Limitation: To the extent permitted by law, Park Pro’s liability for any claims arising from the services or the website is limited to the amount paid for the services. <br />

                                        c. Indemnification: You agree to indemnify and hold Park Pro and its affiliates harmless from any claims, damages, liabilities, or expenses arising out of your use of the services or the website.


                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>12.	Account Protection </h5>
                                    <p>Your password is the key to Your account. You shall be solely responsible for all the activities happening under Your username and You shall be solely responsible for keeping Your password secure. Do not disclose Your password to anyone. If You share Your password or Your personal information with others, You shall be solely responsible for all actions taken under Your username and You may lose substantial control over Your personal information and may be subject to legally binding actions taken on Your behalf. Therefore, if Your password has been compromised for any reason, You should immediately change it.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>13.	User Accounts </h5>
                                    <p>You will be required to enter a valid 10-digit phone number while registering on Park Pro Mobile App (a “User Account”). By registering Your phone number with us, You consent to be contacted by Us via phone calls, SMS notifications, and/or e-mails, in case of any subscription/service updates.
                                        You agree that You will never divulge or share access or access information to Your User Account with any third party for any reason. You also agree to that You will create, use, and access only one User Account, and that You will not access the Mobile app using multiple User Accounts.


                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>14.	Termination Of Usage  </h5>
                                    <p>User access to all or part of this Mobile app may be terminated or suspended at any time, without notice and for any reason that deems such an action as justified by Park Pro.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>15.	Privacy Policy   </h5>
                                    <p>Your privacy is important to us.Our Privacy Policy outlines how we collect, use, and protect your personal information.By using our website, you consent to the practices described in the Privacy Policy.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>16.	Disclaimer And Limitation of Liability </h5>
                                    <p>THE INFORMATION AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS.CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN.
                                        PARK PRO MAY MAKE IMPROVEMENTS OR CHANGES TO SITE AT ANY TIME.ANY ADVICE RECEIVED VIA THE SITE SHOULD NOT BE RELIED UPON FOR ACTUAL USE AND IMPLICATIONS OR ADVICES AND YOU SHOULD CONSULT A REGISTERED AND OFFICIAL AUTHORITY / ORGANISATION FOR SPECIFIC ADVICE TAILORED TO YOUR SITUATION.
                                        WE MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY PURPOSE.TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SERVICE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED ON “AS IS” AND WHERE IS BASIS WITHOUT WARRANTY OR GUARANTEE OR CONDITION OF ANY KIND.PARK PRO HEREBY DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS SERVICE INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON - INFRINGEMENT.
                                        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL WE BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, OFFICE ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF SITE, WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY INFORMATION, SERVICE, RELATED THINGS OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY OR OTHERWISE, EVEN IF PARK PRO OR ANY OF ITS SUPPLIERS HAVE BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.BECAUSE SOME STATES / JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.IF YOU ARE DISSATISFIED WITH ANY PORTION OF SITE, OR WITH ANY OF THESE TERMS OF USE, YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING OF THE SITE.
                                        WE ALSO DON’T CONTROL WHAT PEOPLE AND OTHERS DO OR SAY, AND WE AREN’T RESPONSIBLE FOR THEIR(OR YOUR) ACTIONS OR CONDUCT(WHETHER ONLINE OR OFFLINE) OR CONTENT(INCLUDING UNLAWFUL OR OBJECTIONABLE CONTENT).WE ALSO AREN’T RESPONSIBLE FOR SERVICES AND FEATURES OFFERED BY OTHER PEOPLE OR COMPANIES, EVEN IF YOU ACCESS THEM THROUGH OUR SERVICE.
                                        You assume all responsibility and risk with respect to Your use of our website, which is provided “as is” without warranties, representations or conditions of any kind, either express or implied, with regard to information accessed from or via our website, including without limitation, all functions and services provided on our website, all of which are provided without warranty of any kind, including but not limited to warranties concerning the availability, accuracy, completeness or usefulness of content or information, uninterrupted access, and any warranties of title, non - infringement, merchantability or fitness for a particular purpose.We do not warrant that our website or its functioning or the services made available thereby will be timely, secure, uninterrupted or error - free, that defects will be corrected, or that our websites or the servers that make our website available are free of viruses or other harmful components.
                                        The use of our website is at Your sole risk and You assume full responsibility for any costs associated with Your use of our website.We will not be liable for any damages of any kind related to the use of our website.
                                        In no event will we, or our affiliates, our or their respective content or service providers, or any of our or their respective directors, officers, agents, contractors, suppliers or Applicants be liable to You for any direct, indirect, special, incidental, consequential, exemplary or punitive damages, losses or causes of action, or lost revenue, lost profits, lost business or sales, or any other type of damage, whether based in contract or tort(including negligence), strict liability or otherwise, arising from Your use of, or the inability to use, or the performance of, our website  or functionality through our website, even if we are advised of the possibility of such damages.
                                        Certain jurisdictions do not allow limitation of liability or the exclusion or limitation of certain damages.In such jurisdictions, some or all of the above disclaimers, exclusions, or limitations, may not apply to You and our liability will be limited to the maximum extent permitted by law.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>17.	Entire Agreement  </h5>
                                    <p>The Terms and any documents expressly referred to in them represent the entire agreement between You and us in relation to the subject matter of the Terms and supersede any prior agreement, understanding or arrangement between You and us, whether oral or in writing.Both You and we acknowledge that, in entering into these Terms, neither You nor we have relied on any representation, undertaking or promise given by the other or implied from anything said or written between You and us prior to such Terms, except as expressly stated in the Terms.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>18.	Waiver  </h5>
                                    <p>No provision of this Agreement shall be deemed to be waived and no breach excused, unless such waiver or consent shall be in writing and signed by Park Pro.Any consent by Park Pro to, or a waiver by Park Pro of any breach by You, whether expressed or implied, shall not constitute consent to, waiver of, or excuse for any other different or subsequent breach.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>19.	Governing Law </h5>
                                    <p>These Terms of Use and Your use of the Site are governed by and construed in accordance with the laws of the State of Maharashtra applicable to agreements made and to be entirely performed within the State of Maharashtra, without regard to its conflict of law principles.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>20.	Contact Information   </h5>
                                    <p>
                                        If a User has any questions concerning Park Pro, the website, this Agreement, the Services, or anything related to any of the foregoing, Park Pro customer support can be reached at the following -
                                        <br/> Email address: <a href="mailto:support@parkproapp.com "> support@parkproapp.com  </a><br/>
                                        In accordance with the Information Technology Act, 2000, and the rules made there under, if You have any grievance with respect to the website or the service, including any discrepancies and grievances with respect to processing of information, you can contact us at the abovementioned email address.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default TermsText;















