import React from "react";

class PrivacyPolicy extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        /**
         * Your ajax will goes here to get data then call setState
         */
    }

    render() {
        return (
            <React.Fragment>
                <section className="ptb-100 gray-light-bg pt-100 mt-100">
                    <div className="container pt-100">
                        <div className="row justify-content-start">
                            <div className="col-md-12 col-lg-8">
                                <div className="section-heading mb-3 text-start">
                                    <h2>Privacy Policy</h2>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    {/* <h5>1. Introduction</h5> */}
                                    <p>
                                        Park Pro, (“we”, “us”) is committed to protecting and respecting your privacy. This Privacy Notice (together with any other documents referred to herein) sets out the basis on which the personal data collected from you, or that you provide to us, will be processed by us in connection with the use of our services. Please read the following carefully to understand our views and practices regarding your personal data and how we will treat it.
                                        We want to provide a safe and secure user experience. We will ensure that the information you submit to us, or which we collect, via various channels (including our website, through written correspondence (including e-mail), or through any of our offices or websites globally), is only used for the purposes set out in this Privacy Policy.
                                        Through this Privacy Policy, we aim to inform you about the types of personal information we collect, the purpose for which we use the information and the ways in which the information is handled. We’ve provided short summaries in this Privacy Policy to help you understand what information we collect, how we use it, and what choices or rights you may have. While these summaries help explain some of these concepts in a simple and clear way, we encourage you to read the entire Privacy Policy to understand our data practices.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>1.	Scope</h5>
                                    <p>
                                        Protecting your personal information is our priority.This Privacy Policy explains how Park Pro processes and protects personal information about:<br />
                                        •	Our registered user<br />
                                        •	prospective users,<br />
                                        •	users of our websites;<br />
                                        (collectively “you” or “your”)<br />
                                        If you would like to contact us, please see the “Contact Us” section of this policy.                                     </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>2.	Information We Collect </h5>
                                    <p>We may collect the following types of personal and sensitive user data:</p>
                                    <p>
                                        <b>  Personal Information:  </b> We collect information that you provide to us when making reservations or using our services. This may include your name, contact details, vehicle information, payment details, and any other information necessary for providing valet parking services.<br /><br />

                                        <b>  Usage Information: </b> We collect information about how you interact with our website and services, including IP addresses, browser types, access times, and pages viewed. This information is collected through cookies and other tracking technologies.<br /><br />

                                        <b>  Location Information:  </b>If you permit location services, we may collect and store information about your location to facilitate valet parking services and enhance your experience.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>3.	Information Collected Automatically</h5>
                                    <p>
                                        Like other online companies, we receive technical information when you use our Services. We use these technologies to analyse how people use the Service, to improve how our Site functions, to save your log-in information for future sessions, and to serve you with advertisements that may interest you.
                                        We and our third-party service providers, including analytics, may automatically collect certain information from you whenever you access or interact with the Service. This information may include, among other information, the browser and operating system you are using, the URL or advertisement that referred you to the Service, the search terms you entered into a search engine that led you to the Service, areas within the Service that you visited, which links you clicked on, which pages or content you viewed and for how long, other similar information and statistics about your interactions, such download errors and length of visits to certain pages and other information commonly shared when browsers communicate with websites. We may combine this automatically collected log information with other information we collect about you. We do this to improve services we offer you, and to improve marketing, analytics, and site functionality.
                                        <br /> <br />
                                        <b>  Payment Information </b> <br />
                                        If you use the Service to make or receive payments, we will also collect certain payment information, such as credit card or other financial account information, and billing address.
                                        <br /><br /><b>  Your Device and Location</b> <br />
                                        When you visit or leave our Services (including some plugins and our cookies or similar technology on the sites of others), we receive the URL of both the site you came from and the one you go to and the time of your visit. We also get information about your network and device (e.g., IP address, proxy server, operating system, web browser and add-ons, device identifier and features, cookie IDs and/or ISP, or your mobile carrier). If you use our Services from a mobile device, that device will send us data about your location based on your phone settings. We will ask you to opt-in before we use GPS or other tools to identify your precise location.
                                        <br /><br /> <b>  Other</b> <br />
                                        Our Services are dynamic, and we often introduce new features, which may require the collection of new information. If we collect materially different personal data or materially change how we collect, use or share your data, we will notify you and may also modify this Privacy Policy.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>4.	How We Use Your Information </h5>
                                    <p>
                                        <b>  Service Provision: </b> We use your personal information to fulfill your valet parking reservations, manage bookings, and provide related services. To provide and improve the Service, complete your transactions, address your inquiries, process your registration, verify the information you provide is valid, and for compliance and internal business purposes. <br />
                                        <br />
                                        <b>  Communication: </b> We may use your contact information to communicate with you regarding your reservations, updates, promotions, and customer support.
                                        <br /><br />
                                        <b>  Improvement and Analysis:  </b> We analyze usage data to improve our website, services, and user experience. This may include analyzing trends, preferences, and user behavior.<br />
                                        <br />
                                        <b>  Marketing: </b>With your consent, we may use your information to send you promotional materials, offers, and news related to our services. We use data (which can include your communications) to investigate, respond to and resolve complaints and for Service issues (e.g., bugs).
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>5.	How We Protect Your Information </h5>
                                    <p>We implement appropriate security measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction.  Your payment information is securely processed using industry-standard encryption technologies.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>6.	Secure Data Handling Procedures </h5>
                                    <p>
                                        At Park Pro, we prioritize the security of user data by implementing robust measures aligned with industry standards. These measures include:<br /><br />
                                        <b>Encryption:</b> We utilize encryption protocols during the transmission and storage of user data. This ensures that all information exchanged between the user's device and our servers remains encrypted, mitigating the risk of unauthorized access or interception.<br /><br />
                                        <b>Access Control:</b> Access to user data is strictly limited to authorized personnel with a genuine need to interact with this information. Our access control mechanisms encompass multi-layer authentication protocols and role-based access restrictions, ensuring that only individuals with explicit permissions can access specific sets of data.<br /><br />
                                        <b>Regular Audits and Assessments:</b> Our security practices undergo frequent and comprehensive audits and assessments to identify potential vulnerabilities, assess risks, and verify the effectiveness of our security measures. By regularly reviewing and updating our security protocols, we strive to maintain a proactive stance against emerging threats and maintain the integrity of user data.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>7.	Data Retention and Deletion Policy: </h5>
                                    <p>
                                        At Park Pro, we uphold a strict data retention policy designed to minimize the retention of personal and sensitive user data. We retain this information only for the necessary duration required to fulfill the purposes outlined in this Privacy Policy or as mandated by applicable laws.<br /><br />
                                        <b>Access and Correction:</b> Users have the right to access and correct their personal data. We provide mechanisms within the app for users to review and update their information to ensure its accuracy and relevance.<br /><br />
                                        <b>Deletion:</b> Upon request, users have the right to request the deletion of their personal data. Unless retention is necessary for legal reasons, compliance with regulatory obligations, or legitimate business purposes, we will promptly and securely delete or anonymize the requested data. We ensure that deleted information is irretrievable from our systems and any associated backups.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>8.	Information Sharing </h5>
                                    <p>We may share your information with third parties, such as our service providers and partners, who assist us in delivering our services. These parties are contractually obligated to protect your information and use it only for the purposes specified.

                                        We may disclose your information if required by law, legal process, or to protect our rights, privacy, safety, or property, as well as that of our users and the public.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>9.	Disclosure </h5>
                                    <p>We may disclose your personal information if we are <b>required by law</b> to do so or if you violate our <b>Terms of Service.</b>
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>10.	Your Choices and Obligation </h5>
                                    <p>
                                        <b> Personal Information:  </b>According to applicable law, you may have certain choices and rights associated with your personal information. You have several rights under the regulations and other relevant laws. These rights include the right to ask us for a copy of your personal information, to correct, delete or restrict processing of your personal information; to obtain the personal information you provide to us for a contract or with your consent in a structured, machine-readable format, and to ask us to share (port) this information to another controller.
                                        In addition, you can object to the processing of your personal information in some circumstances (in particular, where we don’t have to process your personal information to meet a contractual or other legal requirement, or where we are using the information for direct marketing).  These rights may be limited, for example, if complying with your request would reveal personal information about another person, where they would infringe the rights of a third party (including our rights) or if you ask us to delete information which we are required by law to keep or have compelling legitimate interests for keeping. We will inform you of any relevant exemptions we are relying on when we respond to your request.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>11.	Cookies and Tracking Technologies  </h5>
                                    <p>
                                        We use cookies and similar tracking technologies to collect usage information and enhance your browsing experience. You can manage cookie preferences through your browser settings.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>12.	Your Choices  </h5>
                                    <p>
                                        You can access, update, or delete your personal information by contacting us. You may also have the right to object to or restrict certain processing activities.
                                        You can opt out of receiving promotional emails by following the instructions in the email or by contacting us.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>13.	Children's Privacy  </h5>
                                    <p>

                                        Our services are not intended for individuals under the age of 18. We do not knowingly collect personal information from children without parental consent.

                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>14.	Security </h5>
                                    <p>
                                        We take appropriate measures to ensure that all personal data is kept secure including security measures to prevent personal data from being accidentally lost, or used or accessed in an unauthorised way. We limit access to your personal data to those who have a genuine business need to know it. Those processing your information will do so only in an authorised manner and are subject to a duty of confidentiality.
                                        We also have procedures in place to deal with any suspected data security breach. We will notify you and any applicable regulator of a suspected data security breach where we are legally required to do so.
                                        Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee the security of your data transmitted through any online means, therefore any transmission remains at your own risk.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>15.	Changes to this Privacy Policy  </h5>
                                    <p>
                                        We may update this Privacy Policy from time to time to reflect changes in our practices or for legal reasons. The updated policy will be posted on our website.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-12">
                                <div className="single-faq mt-4">
                                    <h5>
                                        16.	Contact Us </h5>
                                    <p>
                                        If you have any questions or concerns about this Privacy Policy, please contact us at <a href="mailto:support@parkproapp.com"> support@parkproapp.com </a> .<br />

                                        By using our website and services, you acknowledge that you have read, understood, and agreed to this Privacy Policy. This policy constitutes a legal agreement between you and Park Pro.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </React.Fragment>
        );
    }
}

export default PrivacyPolicy;























