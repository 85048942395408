  module.exports = {
    contact: {
      title: "Looking for a excellent Business idea?",
      description:
        "Give us a call or drop by anytime, we endeavour to answer all enquiries within 24 hours on business days.",

      addressTitle: "Company Location",
      addressIcon: "fas fa-location-arrow",
      address: "100 Yellow House, Mn Factory, United State, 13420",
      emailTitle: "Email Address",
      emailIcon: "fas fa-envelope",
      email: "support@parkproapp.com"
    }
  };
